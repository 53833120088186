import React, { useState, useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next';
import { Input, Button, Dropdown, Modal, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import './typeprac.css'

const idols = [
  {
    label: 'Seventeen',
    key: 'seventeen',
  },
  {
    label: 'BTS',
    key: 'bts',
  },
  {
    label: 'TOMORROW X TOGETHER',
    key: 'txt',
  },
  {
    label: 'NewJeans',
    key: 'newjeans',
  },
  {
    label: 'iVE',
    key: 'ive',
  },
  {
    label: 'aespa',
    key: 'aespa',
  },
]
const songs = [
  {
    key: 'seventeen',
    songs: [
      {
        label: 'Yawn(하품) - Seventeen',
        key: 'Yawn(하품)',
      },
      {
        label: 'Dust(먼지) - Seventeen',
        key: 'Dust(먼지)',
      },
      {
        label: 'Circles(돌고 돌아) - Seventeen',
        key: 'Circles(돌고_돌아)',
      },
      {
        label: 'Imperfect Love(매일 그대라서 행복하다) - Seventeen',
        key: 'Imperfect_Love(매일_그대라서_행복하다)',
      },
      {
        label: 'Thanks(고맙다) - Seventeen',
        key: 'Thanks(고맙다)',
      }
    ]
  },
  {
    key: 'bts',
    songs: [
      {
        label: 'Spring Day(봄날) - BTS',
        key: 'Spring_Day(봄날)',
      },
    ]
  },
  {
    key: 'txt',
    songs: [
      {
        label: 'Skipping Stones(물수제비) - TXT',
        key: 'Skipping_Stones(물수제비)',
      },
    ]
  },
  {
    key: 'newjeans',
    songs: [
      {
        label: 'Beautiful Restriction(아름다운 구속) - NewJeans',
        key: 'Beautiful_Restriction(아름다운_구속)',
      },
    ]
  },
  {
    key: 'ive',
    songs: [
      {
        label: 'After LIKE - IVE',
        key: 'After_LIKE',
      },
    ]
  },
  {
    key: 'aespa',
    songs: [
      {
        label: 'Forever(약속) - aespa',
        key: 'Forever(약속)',
      },
    ]
  },

];

function TypePrac() {

  const [text, setText] = useState(''); // Current text to type
  const [question, setQuestion] = useState([]); // Current text to type
  const [idol, setIdol] = useState('seventeen'); // Current text to type
  const [song, setSong] = useState('Yawn(하품)'); // Current text to type

  const [input, setInput] = useState(''); // User's input
  const [currLine, setCurrLine] = useState(0); // User's input
  const [marks, setMarks] = useState(0); // Number of correct marks
  const [misses, setMisses] = useState(0); // Number of incorrect marks
  const [seconds, setSeconds] = useState(-1); // Countdown timer

  const [openModal, setOpenModal] = useState(true);

  useEffect(() => {
    loadQuestion();
    /* document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    }; */
  }, []);

  useEffect(() => {
    setSong(songs.find((d)=>d.key===idol).songs[0].key);
  }, [idol]);

  useEffect(() => {
    loadQuestion();
    setInput('');
  }, [song]);

  const loadQuestion = async() => {
    await getTxtFile(`${window.location.origin}/res/lyrics/${idol}/${song}.txt`).then(async(text) => {
      const question = handleTxtFile(text);
      setQuestion(question);
    })
  };

  const getTxtFile = (url) => {
    return fetch(url).then(function (response) {
      return response.text()
    })
  }

  const handleTxtFile = (text) => {
    var arr = text.split('\n').filter(d => d !== '');
    return arr;
  }

  const contructModal = () => {
    return (
      <Modal
        title="Korean Typing Practise (K-POP ver.)"
        open={openModal}
        onCancel={() => setOpenModal(false)}
        footer={null}
      >
        <p>Who's songs would you like to type?</p>
        <div className="modal-container">
          {
            idols.map((d, i) => {
              return <img id={i} className="img-idol" onClick={() => {setOpenModal(false);setIdol(d.key)}} src={`${window.location.origin}/res/idol/${d.key.toLowerCase()}.jpeg`}></img>
            })
          }
        </div>
        <hr></hr>
        <ul>
          <li>Songs with less English are chosen.</li>
          <li>English words are translated into Korean (e.g. Forever - 포에버).</li>
          <li>More songs are coming!</li>
        </ul>
      </Modal>
    );
  }

  const startTimer = () => {
    setSeconds(60);
    setCurrLine(0);
    setMarks(0);
    setMisses(0);
    setInput('');
  };

  useEffect(() => {
    if (seconds > 0) {
      const timer = setTimeout(() => {
        setSeconds(seconds - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (seconds === 0) {
      calCurrInput();
    }
  }, [seconds]);

  const handleKeyDown = (e) => {
    if (e.isComposing || e.keyCode === 229)  return; // for Korean input
    if (e.key === 'Enter') {
      calCurrInput();
      setInput('');
      if (question[currLine+1]) {
        setCurrLine(currLine+1);
      } else {
        setCurrLine(0);
      }
    }
  }
  const calCurrInput = () => {
    let mark = 0;
    let miss = 0;
    for (let i=0; i<question[currLine].length; i++) {
      if (question[currLine][i] !== ' '){
        if (question[currLine][i] === input[i]) {
          mark++;
        } else {
          miss++;
        }
      }
    }
    setMarks(marks + mark);
    setMisses(misses + miss);
  }

  const onClick = ({ key }) => {
    setSong(key);
  };

  return (
    <div className="typeprac-container">
      {
        contructModal()
      }
      <div>
        <div className="header">
          <div>
            <h2>Korean Typing Practise (K-POP Ver.)</h2>
            <div className="txt-ques">Question:</div>
            <div>
              <Dropdown
                menu={{
                  items: songs && idol && songs.find(d=>d.key===idol)? songs.find(d=>d.key===idol).songs : [],
                  onClick,
                }}
                className="songs"
                disabled={seconds > 0}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Button>
                    <span className="txt-song-name">
                    {
                      songs.find((d)=>d.key===idol).songs.find((d)=>d.key===song) ? songs.find((d)=>d.key===idol).songs.find((d)=>d.key===song).label : ''
                    }
                    </span>
                    <DownOutlined />
                  </Button>
                </a>
              </Dropdown>
            </div>
          </div>
          <img className="img-idol" onClick={() => setOpenModal(true)} src={`${window.location.origin}/res/idol/${idol.toLowerCase()}.jpeg`}></img>
        </div>
        <div>
          <div className="question-container">
            <p class="curr">
              {question[currLine] && question[currLine].split('').map((d, i) => {
                if (input[i]) {
                  if (input[i] && d === input[i]) {
                    return <span style={{ color: 'green' }}>{d}</span>;
                  } else if (i !== input.length - 1){
                    return <span style={{ color: 'red' }}>{d}</span>;
                  } else {
                    return <span>{d}</span>;
                  }
                } else {
                  return <span>{d}</span>;
                }
              })}
            </p>
            <p>{question[currLine+1]}</p>
          </div>
        </div>
        <div>
          <Input
            className='txt-input'
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleKeyDown}
            disabled={seconds <= 0}
          />
        </div>
        {
          seconds >= 0 &&
          <p>{seconds} seconds remaining</p>
        }
        {seconds !== 0 ? (
          <div>
            <p>Marks: {marks}</p>
            <p>Misses: {misses}</p>
          </div>
        ) : (
          <div>
            <p>Time is up!</p>
            <p class="wpm">Total Marks: {marks} wpm</p>
            <p>Total Misses: {misses}</p>
          </div>
        )}
        <div>
          {
            seconds === -1 ?
            <Button onClick={startTimer}>Start</Button>
            :
            seconds === 0 ?
            <Button onClick={startTimer}>Restart</Button>
            :
            <Button disabled>Start</Button>
          }
        </div>
      </div>
    </div>
  )
}

export default TypePrac
