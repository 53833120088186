import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Input, Space } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

import './home.css'

function Home() {

  const { t, i18n } = useTranslation();

  const contructHomeContent = () => {
    return <div>A fun space for fangirls to learn Korean ^_^</div>;
  }

  return (
    <div className="home-container spectrum-background">
      {
        contructHomeContent()
      }
    </div>
  )
}

export default Home
