import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom'
import { ConfigProvider, Input } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

import Home from './containers/home/Home';
import TypePrac from './containers/typeprac/TypePrac';
import Blog from './containers/blog/Blog';

import './App.css';

const HOMEROUTE = '/'
//const KDRAMAROUTE = '/kdrama/:name/:ep'
//const VOCABROUTE = '/vocab/:name'
//const DICTIONARYROUTE = '/dict/:vocab'
const TYPEPRACROUTE = '/typeprac'
const BLOGROUTE = '/blog'

function App() {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    // on enter page
    const savedPageLang = localStorage.getItem("pageLang");
    if (savedPageLang) {
      i18n.changeLanguage(savedPageLang);
    }
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          // colorPrimary: '#252627', //'#5f8ec3',
        },
      }}
    >
      <header className="App-header">
        <h1>FANGIRLAND.COM</h1>
        <div className="menu">
          <div className={`menu-item ${window.location.pathname === '/' ? 'active' : ''}`} onClick={()=>navigate('/')}>HOME</div>
          <div className={`menu-item ${window.location.pathname === '/typeprac' ? 'active' : ''}`} onClick={()=>navigate('/typeprac')}>Korean Typing Practise</div>
        </div>
      </header>

      <body>
        <Routes>
          <Route path={HOMEROUTE} element={<Home />} />
          <Route path={TYPEPRACROUTE} element={<TypePrac />} />
        </Routes>
      </body>

      <footer>
        <div><small>Copyright © 2023 fangirland.com - All Rights Reserved.</small></div>
        <div><small>fangirland.contact@gmail.com</small></div>
      </footer>
    </ConfigProvider>
  )
}

export default App;
